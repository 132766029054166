.non-styled-link {
  text-decoration: none;
  display: inline-block;
  overflow: visible;
}

.non-styled-link button {
  opacity: 1;
  background: transparent;
  border: 2px solid #23d997; /* Green bounding box */
  color: #ffffff; 
  font-size: 1.5rem; /* Increase font size */
  font-weight: 500;
  padding: 0.80rem 1.7rem; /* Add more padding for bigger button */
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  z-index: 1; /* Ensure text is on top */
}

.non-styled-link button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: #23d997; /* Green underline */
  transition: width 0.3s ease;
  z-index: -1; /* Put the underline behind the text */
}

.non-styled-link button:hover::after {
  width: 100%;
}

.non-styled-link button:hover {
  color: #ffffff; /* Keep text white on hover */
}
